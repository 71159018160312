import React, {useRef}  from "react"

import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"



const SteelBearings = () => {
  //javascript
  const title = 'Steel Bearings';
  const metatitle = 'Steel Bearings';
  const description = 'Steel Bearings : Limit states and threshold values ';
  const metadescription = description;
  const style2 = {
    marginBottom: 80
  }

  const scrollToRef = (ref) => window.scrollTo({
		left:0,
		top:ref.current.offsetTop,
		behavior: 'smooth'
	});
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  
  

	const executeScroll = (e, ref) => {
		e.preventDefault();
		scrollToRef(ref);
  }
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
          <li>
            <a className="more" href="../elastomeric-bearings">Elastomeric Bearings</a>
          </li>
          <li>
            <a className="more" href="../lead-rubber-bearings">Lead Rubber Bearings (LRB)</a>
          </li>
          <li>
            <a className="more" href="../elastomeric-bearings-ptfe">Elastomeric Bearings with PTFE Sheet</a>
          </li>
          <li>
            <a className="more actclass" href="../steel-bearings">Steel Bearings</a>
          </li>
        </ul>
      </nav>
      <div className="content-table">
      <h2>Table 4: Steel Bearings : Limit states and threshold values</h2>
      <h3>(R): limit state definition based on soa ,(E): Experimental, (S): Simulation or analysis</h3>
      <p><strong>HSBF</strong>=High-Steel Bearing Fixed, <strong>LSBF</strong>= Low-Steel Bearing Fixed, <strong>HSBR</strong>= High-Steel Bearing Rocker, <strong>LSBS</strong>= Low-Steel Bearing Sliding</p>
      <table>
        <thead>
          <tr>
            <th colSpan={4}>Engineering Demand Parameter: Relative displacement Δ (mm)</th>
          </tr>
          <tr>
            <th>Reference</th>
            <th>Limit State</th>
            <th>Δ (mm)</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button>. (R) Cardone, D. (2013) <a href="https://doi.org/10.1002/eqe.2396" target="_blank">https://doi.org/10.1002/eqe.2396</a></td>
            <td>LS1</td>
            <td>-, HSBF+LSBF<br />d<sub>max</sub>, HSBR+LSBS<br />d<sub>max</sub>: displacement capacity of the device under non-seismic conditions</td>
            <td>Exceedance of displacement capacity under non-seismic loads</td>
          </tr>
          <tr>
            <td>LS2</td>
            <td>d<sub>fix</sub>, HSBF+LSBF<br />d<sub>lim</sub>, HSBR<br />d<sub>max</sub>+1/3(d<sub>uns</sub>-d<sub>max</sub>), LSBS<br />d<sub>fix</sub>: displacement corresponding to collapse of the device or anchor bolts<br />d<sub>uns</sub>: displacement corresponding to deck unseating</td>
            <td>Collapse of device or anchor bolts, or vertical instability</td>
          </tr>
          <tr>
            <td>LS3</td>
            <td>d<sub>fix</sub>+2/3(d<sub>uns</sub>-d<sub>fix</sub>), HSBF+LSBF<br />d<sub>lim</sub>+1/3(d<sub>uns</sub>-d<sub>lim</sub>), HSBR<br />d<sub>max</sub>+2/3(d<sub>uns</sub>-d<sub>max</sub>), LSBS</td>
            <td>Damage state between LS2 and LS3</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>d<sub>uns</sub></td>
            <td>Unseating</td>
          </tr>
          <tr className="even">
            <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref2)}>[2]</button>. (E) LaFave et al. (2013)</td>
            <td>LS3</td>
            <td>20 – 38 mm, LSBF Transv.<br />20 – 41 mm, LSBF Long.</td>
            <td>Fracture of anchors</td>
          </tr>
          <tr className="even">
            <td>LS4</td>
            <td>19mm1, LSBF Long.+Transv.</td>
            <td>Sliding</td>
          </tr>
          <tr>
            <td rowSpan={16}><button onClick={ e => executeScroll(e, Ref3)}>[3]</button>. (E) Nielson, B. (2005)</td>
            <td rowSpan={4}>LS1</td>
            <td>6, HSBF Long.<br />6, HSBF Transv.</td>
            <td rowSpan={4}>Appearance of cracks on concrete piers</td>
          </tr>
          <tr>
            <td>6, LSBF Long.<br />6, LSBF Transv.</td>
          </tr>
          <tr>
            <td>50, HSBR Long.<br />6, HSBR Transv.</td>
          </tr>
          <tr>
            <td>50, LSBS Long.<br />6, LSBS Transv.</td>
          </tr>
          <tr>
            <td rowSpan={4}>LS2</td>
            <td>20, HSBF Long.<br />20, HSBF Transv.</td>
            <td rowSpan={4}>Prying of bearings and severe deformation of anchor bolts</td>
          </tr>
          <tr>
            <td>20, LSBF Long.<br />20, LSBF Transv.</td>
          </tr>
          <tr>
            <td>100, HSBR Long.<br />20, HSBR Transv.</td>
          </tr>
          <tr>
            <td>100, LSBS Long.<br />20, LSBS Transv.</td>
          </tr>
          <tr>
            <td rowSpan={4}>LS3</td>
            <td>40, HSBF Long.<br />40, HSBF Transv.</td>
            <td rowSpan={4}>Complete fracture of anchor bolts</td>
          </tr>
          <tr>
            <td>40, LSBF Long.<br />40, LSBF Transv.</td>
          </tr>
          <tr>
            <td>150, HSBR Long.<br />40, HSBR Transv.</td>
          </tr>
          <tr>
            <td>150, LSBS Long.<br />40, LSBS Transv.</td>
          </tr>
          <tr>
            <td rowSpan={4}>LS4</td>
            <td>255, HSBF Long.<br />255, HSBF Transv.</td>
            <td rowSpan={4}>Unseating</td>
          </tr>
          <tr>
            <td>255, LSBF Long.<br />255, LSBF Transv.</td>
          </tr>
          <tr>
            <td>255, HSBR Long.<br />255, HSBR Transv.</td>
          </tr>
          <tr>
            <td>255, LSBS Long.<br />255, LSBS Transv.</td>
          </tr>
        </tbody>
        </table>
        </div>
        <div className="references">
          <h4>References</h4>
          <ol>
            <li ref={Ref1}>Cardone, D. (2013). Displacement limits and performance displacement profiles in support of direct displacement-based seismic assessment of bridges, Earthquake Engineering &amp; Structural Dynamics, DOI: http://dx.doi.org/10.1002/eqe.2396.</li>
            <li ref={Ref2}>LaFave, J., Fahnestock, L., Foutch, D., Steelman, J., Revell, J., Filipov, E., Hajjar, J. (2013). Experimental Investigation of the Seismic Response of Bridge Bearings, Research Report No.  FHWA-ICT-13-002,  Illinois Center for Transportation. </li>
            <li ref={Ref3}>Nielson, B. G. (2005). Analytical Fragility Curves for Highway Bridges in Moderate Seismic Zones, PhD Thesis, Georgia Institute of Technology, December, 2005.</li>
          </ol>
        </div>
      
    </Layout>
  )
}

export default SteelBearings
